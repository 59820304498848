import React from 'react'
import { ReactComponent as Info } from '../img/info.svg'

type AlertProps = {
  text: string
  variant: string
}

function Alert(props: AlertProps): JSX.Element {
  const { text, variant } = props
  return (
    <div
      className={`alert alert-${variant} d-flex align-items-center px-2`}
      role="alert"
    >
      <div className="pl-2">
        <Info />
      </div>
      <span className="pl-3">{text}</span>
    </div>
  )
}

export default Alert
