import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { useAppSelector } from '../redux/hooks'
import '../styles/footer.scss'

type FooterProps = {
  storeLogo: string | undefined
}

function Footer(props: FooterProps): JSX.Element {
  const { storeLogo } = props

  const textProperties = useAppSelector((state) => state.textProperties.value)

  return (
    <div className="App-footer mt-auto">
      <footer>
        <Container>
          <Row className="footer-row align-items-center">
            <Col xs={12} sm={6}>
              <p>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textProperties.storeUrl}
                >
                  {textProperties.frontPage}
                </a>
              </p>
              <p className="footer-text">
                {textProperties.sokAddress}
                <br />
                {textProperties.businessId}
              </p>
              <p>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textProperties.termsOfUseUrl}
                >
                  {textProperties.termsOfUse}
                </a>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textProperties.shippingAndDeliveryUrl}
                >
                  {textProperties.shippingAndDelivery}
                </a>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textProperties.privacyNoticeUrl}
                >
                  {textProperties.privacyNotice}
                </a>
              </p>
            </Col>
            <Col
              xs={12}
              sm={6}
              className="d-none d-sm-flex justify-content-sm-center"
            >
              <img src={storeLogo} className="App-logo" alt="footer-logo" />
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default Footer
