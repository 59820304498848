import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import properties from '../helpers/properties'
import { IDraftReturnStartResponseWrapper } from '../models/om-models'
import { useAppSelector } from '../redux/hooks'

type OrderNumberInfoProps = {
  data: IDraftReturnStartResponseWrapper | undefined
}

function OrderNumberInfo(props: OrderNumberInfoProps): JSX.Element {
  const { data } = props

  const textProperties = useAppSelector((state) => state.textProperties.value)

  return (
    <Col xs={12} sm={7} className="pb-3">
      <Row className="d-flex flex-column">
        <Col xs={12} className="d-sm-none font-weight-bold ordernumber-small">
          {textProperties.orderNumber}: {data?.orderNo}
        </Col>
        <Col xs={12} className="d-none d-sm-block">
          <span className="small">{textProperties.orderNumber}</span>
          <br />
          <span className="ordernumber-big font-weight-bold">
            {data?.orderNo}
          </span>
          <br />
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs={12} sm={9}>
          <pre>{textProperties.startPageInfo}</pre>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={7}>
          <Link
            to={properties.returnViewUrl}
            className="btn btn-primary mt-3 mt-sm-0 py-2 mb-5 w-100 font-weight-bold"
          >
            {textProperties.startReturn}
          </Link>
        </Col>
      </Row>
    </Col>
  )
}

export default OrderNumberInfo
