import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import textProperties from '../helpers/commonTextProperties'
import properties from '../helpers/properties'

export function ErrorView(): JSX.Element {
  return (
    <div>
      <Row>
        <Col>
          <h1 data-testid="heading">{textProperties.genericErrorMessage}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link
            to={properties.startViewUrl}
            className="App-link btn-link font-weight-bold"
          >
            {textProperties.returnLink}
          </Link>
        </Col>
      </Row>
    </div>
  )
}
