import { CustomTextProperties } from '../models/text-properties-model'

const textProperties: CustomTextProperties = {
  onlineStoreItemReturn: 'Verkkokaupasta ostettujen tuotteiden palautus',
  startPageInfo:
    'Voit palauttaa yhden tai useamman tuotteen tilaukseltasi. Sokoksen verkkokaupasta ostetuilla tuotteilla on ilmainen palautusoikeus.',
  frontPage: 'Sokos.fi etusivu',
  summaryInfo:
    'Kun vahvistat palautuksen, Sokos saa tiedon tulevasta palautuksesta.',
  toStore: 'Sokos.fi etusivulle',
  storeUrl: 'https://www.sokos.fi',
  frequentlyAskedUrl:
    'https://www.sokos.fi/fi/sokos/asiakaspalvelu/usein-kysyttya#onko-tuotteella-palautus-oikeutta',
  onlineStoreReturnPolicyUrl: 'https://www.sokos.fi/info/palautus',
  returnRegisteredInfo:
    'Seuraavaksi voit palauttaa tuotteet meille samalla tavalla, kuin millä ne toimitettiin sinulle. Mikäli toimitustapana oli Nouto myymälästä — palauta tuotteet paketin mukana tulleen palautustarran avulla Postin kautta. Jos muutat mielesi palautuksen rekisteröinnin jälkeen ja haluat pitää yhden tai useamman tuotteen, voit vain unohtaa palautuksen — erillistä yhteydenottoa asiakaspalveluun ei tarvita.',
  reclamationsUrl: 'https://www.sokos.fi/fi/sokos/asiakaspalvelu/reklamaatio',
  termsOfUseUrl: 'https://www.sokos.fi/fi/sokos/verkkokauppa/kayttoehdot',
  shippingAndDeliveryUrl:
    'https://www.sokos.fi/fi/sokos/verkkokauppa/tilaus--ja-toimitusehdot',
  privacyNoticeUrl:
    'https://www.sokos.fi/fi/sokos/verkkokauppa/tietosuoja-ja-turvallisuus',
  customerServiceUrlLabel: 'sokos.fi',
  customerServiceUrl:
    'https://www.sokos.fi/fi/sokos/asiakaspalvelu/asiakaspalvelun-ohjeet',
  customerServiceOpeningTimes: 'Ma-Pe 8-22, La 10-18',
}

export default textProperties
