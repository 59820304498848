import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initializeStore = createAsyncThunk('initializeStore', async () => {
  if (window.location.hostname === 'palautus.sokos.fi') {
    return 'sokos'
  }
  if (window.location.hostname === 'palautus.prisma.fi') {
    return 'prisma'
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlStore = urlParams.get('store')
  if (urlStore) {
    return urlStore
  }
  if (process.env.REACT_APP_STORE) {
    return process.env.REACT_APP_STORE
  }
  return ''
})

// Define a type for the slice state
interface StoreState {
  value: string | undefined
}

// Define the initial state using that type
const initialState: StoreState = {
  value: undefined,
}

export const storeSlice = createSlice({
  name: 'store',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStore: (state, action: PayloadAction<string | undefined>) => {
      state.value = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeStore.fulfilled, (state, action) => {
      state.value = action.payload
    })
  },
})

export const { setStore } = storeSlice.actions

export default storeSlice.reducer
