const properties = {
  returnProcessStartPost:
    '/returns/draft-returns/start-draft-returns-and-combine/',
  imageUrl: 'https://cdn.s-cloud.fi/v1/product/ean/',
  imageUrlParams: '_kuva1.jpg?width=57&height=72',
  startViewUrl: '/',
  returnViewUrl: '/return',
  successViewUrl: '/success',
  errorViewUrl: '/error',
  returnNumberSearchParam: '?returnNumber=',
  storeSearchParam: '&store=',
}

export default properties
