import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import './App.scss'
import Api from './common/api/api'
import Footer from './common/components/Footer'
import properties from './common/helpers/properties'
import prismaLogo from './common/img/logo-prisma.svg'
import sokosLogo from './common/img/logo-sokos.svg'
import { IDraftReturnStartResponseWrapper } from './common/models/om-models'
import { ReturnInformation } from './common/models/ReturnInformation'
import { useAppSelector } from './common/redux/hooks'
import { initializeStore } from './common/redux/reducers/StoreSlice'
import { fetchTextProperties } from './common/redux/reducers/TextPropertiesSlice'
import ConfirmationView from './common/views/ConfirmationView'
import ReturnView from './common/views/ReturnView'
import StartView from './common/views/StartView'
import { ErrorView } from './common/views/ErrorView'
import { DraftReturnLine } from './common/components/Product'

function App(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [orderNumberAvailable, setOrderNumberAvailable] =
    useState<boolean>(false)
  const [data, setData] = useState<IDraftReturnStartResponseWrapper>()
  const [returnNumber, setReturnNumber] = useState<string | undefined>(
    undefined,
  )
  const [cookies, setCookie] = useCookies(['returnNumber'])

  const [returnData, setReturnData] = useState<ReturnInformation>()

  const textProperties = useAppSelector((state) => state.textProperties.value)
  const store = useAppSelector((state) => state.store.value)

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const getReturnNumber = (): string | undefined => {
      // Try to find returnNumber from search params
      const searchParams = new URLSearchParams(window.location.search)
      const searchParamReturnNumber = searchParams.get('returnNumber')
      if (searchParamReturnNumber) {
        setCookie('returnNumber', searchParamReturnNumber, {
          sameSite: 'lax',
        })
        return searchParamReturnNumber
      }
      // If returnNumber was not found from search params
      // try to find returnNumber from cookies
      const storedReturnNumber = cookies.returnNumber
      if (storedReturnNumber) {
        return storedReturnNumber
      }
      // If returnNumber was not found from search params
      // or from cookies, return undefined
      return undefined
    }

    const returnNo = getReturnNumber()
    if (returnNo) {
      setReturnNumber(returnNo)
      setOrderNumberAvailable(true)
    } else {
      setLoading(false)
    }
  }, [cookies, setCookie])

  useEffect(() => {
    dispatch(initializeStore())

    const root = document.documentElement
    root?.style.setProperty(
      '--bootstrap-primary-color',
      store === 'sokos' ? '#002b44' : '#e5007d',
    )
    root?.style.setProperty(
      '--background-color',
      store === 'sokos' ? '#002b44' : '#018749',
    )
    root?.style.setProperty(
      '--primary-color',
      store === 'sokos' ? '#002b44' : '#333',
    )
    root?.style.setProperty(
      '--checkbox-color',
      store === 'sokos' ? '#002b44' : '#018749',
    )

    dispatch(fetchTextProperties(store))
  }, [dispatch, store])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (returnNumber) {
        const dataFromApi = await Api.startProcess(returnNumber)
        setData(dataFromApi)
        setLoading(false)
      }
    }
    loadData()
  }, [returnNumber])

  useEffect(() => {
    const sendReturnToApi = async (): Promise<void> => {
      if (returnNumber && returnData) {
        try {
          await Api.completeProcess(returnNumber, returnData)
          history.replace('/success')
        } catch (err) {
          history.replace('/error')
        }
      }
    }
    sendReturnToApi()
  }, [returnNumber, returnData, history])

  const setReturnDataHandler = (
    returnLines: DraftReturnLine[],
    bankAccountNo?: string,
  ): void => {
    const json: ReturnInformation = {
      draftReturnLines: returnLines,
      bankAccountNumber: bankAccountNo,
    }

    setReturnData(json)
  }

  const capitalizeFirstLetter = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  const getLogo = (): string | undefined => {
    if (store === 'sokos') {
      return sokosLogo
    }
    if (store === 'prisma') {
      return prismaLogo
    }
    return undefined
  }

  return (
    <div className={`App d-flex flex-column ${store}`}>
      <Helmet>
        <title>
          {store
            ? `${capitalizeFirstLetter(store)} - ${textProperties.returnForm}`
            : null}
        </title>
        <meta
          name="description"
          content={
            store
              ? `${capitalizeFirstLetter(store)} - ${textProperties.returnForm}`
              : ''
          }
        />
        <link rel="icon" href={`/favicon-${store}.ico`} />
        <link rel="apple-touch-icon" href={`/touch-icon-${store}.png`} />
        <link rel="manifest" href={`/manifest-${store}.json`} />
        {store === 'sokos' ? (
          <meta name="theme-color" content="#002b44" />
        ) : (
          <meta name="theme-color" content="#018749" />
        )}
      </Helmet>
      <header className="App-header d-flex flex-column align-items-center justify-content-center mb-sm-4">
        {store ? (
          <img
            src={getLogo()}
            className={`App-logo logo-${store}`}
            alt="header-logo"
          />
        ) : null}
      </header>
      <Container className="my-5">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">{textProperties.loading}</span>
            </Spinner>
          </div>
        ) : (
          <Routes
            data={data}
            setReturnDataHandler={setReturnDataHandler}
            orderNumberAvailable={orderNumberAvailable}
          />
        )}
      </Container>
      <Footer storeLogo={getLogo()} />
    </div>
  )
}

function Routes(props: {
  data: IDraftReturnStartResponseWrapper | undefined
  setReturnDataHandler: (
    returnLines: DraftReturnLine[],
    bankAccountNo?: string | undefined,
  ) => void
  orderNumberAvailable: boolean
}): JSX.Element {
  const { data, setReturnDataHandler, orderNumberAvailable } = props
  return (
    <Switch>
      <Route path={properties.returnViewUrl}>
        <ReturnView data={data} setReturnDataHandler={setReturnDataHandler} />
      </Route>
      <Route path={properties.successViewUrl}>
        <ConfirmationView />
      </Route>
      <Route path={properties.errorViewUrl}>
        <ErrorView />
      </Route>
      <Route path={properties.startViewUrl}>
        <StartView data={data} orderNumberAvailable={orderNumberAvailable} />
      </Route>
    </Switch>
  )
}

export default App
