import properties from '../helpers/properties'
import { IDraftReturnStartResponseWrapper } from '../models/om-models'
import { ReturnInformation } from '../models/ReturnInformation'

export default class Api {
  private static get baseUrl(): string {
    if (!process.env.REACT_APP_ORDER_MANAGEMENT_API_ORIGIN) {
      throw new Error('API origin undefined')
    }
    return process.env.REACT_APP_ORDER_MANAGEMENT_API_ORIGIN
  }

  private static get commonHeaders(): HeadersInit {
    return {
      'Content-Type': 'application/json',
    } as HeadersInit
  }

  static startProcess(
    orderNumber: string,
  ): Promise<IDraftReturnStartResponseWrapper> {
    const url = this.baseUrl + properties.returnProcessStartPost + orderNumber
    return this.post<IDraftReturnStartResponseWrapper>(url)
  }

  static completeProcess(
    orderNumber: string,
    data: ReturnInformation,
  ): Promise<void> {
    const apiLines = data.draftReturnLines.map(
      ({ itemId, lineNo, quantity, reasonKey, returnReasonDescription }) => ({
        itemId,
        lineNo,
        quantity,
        returnReason: reasonKey,
        returnReasonDescription,
      }),
    )
    const url = this.baseUrl + properties.returnProcessStartPost + orderNumber
    return this.put<void>(url, {
      bankAccountNumber: data.bankAccountNumber,
      draftReturnLines: apiLines,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static async post<T>(url: string, data?: any): Promise<T> {
    const response = await fetch(url, {
      method: 'POST',
      headers: this.commonHeaders,
      body: JSON.stringify(data),
    })
    if (response.ok) {
      const text = await response.text()
      return text.length ? JSON.parse(text) : undefined
    }
    throw new Error(response.statusText)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static async put<T>(url: string, data?: any): Promise<T> {
    const response = await fetch(url, {
      method: 'PUT',
      headers: this.commonHeaders,
      body: JSON.stringify(data),
    })
    if (response.ok) {
      const text = await response.text()
      return text.length ? JSON.parse(text) : undefined
    }
    throw new Error(response.statusText)
  }
}
