import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import properties from '../helpers/properties'
import { IDraftReturnStartResponseWrapper } from '../models/om-models'
import Alert from '../components/Alert'
import CustomerServiceInfo from '../components/CustomerServiceInfo'
import Product, { DraftReturnLine } from '../components/Product'
import ReturnSummary from '../components/ReturnSummary'
import { useAppSelector } from '../redux/hooks'

type ReturnViewProps = {
  data: IDraftReturnStartResponseWrapper | undefined
  setReturnDataHandler: (
    returnLines: DraftReturnLine[],
    bankAccountNo?: string,
  ) => void
}

function ReturnView(props: ReturnViewProps): JSX.Element {
  const [draftReturnLines, setDraftReturnLines] = useState<DraftReturnLine[]>(
    [],
  )

  const textProperties = useAppSelector((state) => state.textProperties.value)
  const store = useAppSelector((state) => state.store.value)

  const { data, setReturnDataHandler } = props

  const setDraftReturnLinesHandler = (drLine: DraftReturnLine): void => {
    const index = draftReturnLines.findIndex(
      (item) => item.itemId === drLine.itemId,
    )
    if (index < 0) {
      setDraftReturnLines([...draftReturnLines, drLine])
    } else {
      const returnLine = [...draftReturnLines]
      returnLine[index] = drLine
      setDraftReturnLines(returnLine)
    }
  }

  const deleteDraftReturnLinesHandler = (itemId: string): void => {
    const lineFound = draftReturnLines.find((item) => item.itemId === itemId)
    if (lineFound) {
      const newReturnLines = draftReturnLines.filter(
        (item) => item.itemId !== itemId,
      )
      setDraftReturnLines(newReturnLines)
    }
  }

  const setSendDataHandler = (bankAccountNo?: string): void => {
    setReturnDataHandler(draftReturnLines, bankAccountNo)
  }

  const areAllShipped = (): boolean => {
    const items = data?.items
    if (items) {
      return items.every((item) => parseFloat(item.pickedQuantity) > 0)
    }
    return true
  }

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <span className="arrow arrow-left" />
          <Link
            to={`${properties.startViewUrl}${properties.returnNumberSearchParam}${data?.draftReturn.draftReturnNo}${properties.storeSearchParam}${store}`}
            className="App-link btn-link font-weight-bold pl-2"
          >
            {textProperties.returnLink}
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h1>{textProperties.chooseItems}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} className="pb-3">
          {areAllShipped() ? null : (
            <Alert variant="info" text={textProperties.allProductsNotShipped} />
          )}
          {data
            ? data.items.map((item) => (
                <Product
                  key={item.orderLineNo}
                  item={item}
                  isShipped={parseFloat(item.pickedQuantity) > 0}
                  setDraftReturnLinesHandler={setDraftReturnLinesHandler}
                  deleteDraftReturnLinesHandler={deleteDraftReturnLinesHandler}
                />
              ))
            : null}
        </Col>
        <Col xs={12} sm={{ span: 5, offset: 1 }}>
          <ReturnSummary
            bankAccountNumberRequired={
              data?.draftReturn.bankAccountNumberRequired
            }
            setSendDataHandler={setSendDataHandler}
            draftReturnLines={draftReturnLines}
            items={data?.items}
          />
          <CustomerServiceInfo />
        </Col>
      </Row>
    </div>
  )
}

export default ReturnView
