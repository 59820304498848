import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import linkIcon from '../img/link-icon.svg'
import { useAppSelector } from '../redux/hooks'

function SimplifiedReturnInfo(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)
  const store = useAppSelector((state) => state.store.value)
  const PrismaReturnInfo = (
    <>
      <p>{textProperties.returnRegisteredInfo}</p>
      <ul>
        <li style={{ paddingBottom: '10px' }}>
          <strong>Jos toimitustapana oli 2 tunnin pikanouto myymälästä</strong>,
          palauta tuote siihen myymälään, josta noudit sen.
        </li>
        <li style={{ paddingBottom: '10px' }}>
          <strong>
            Jos toimitimme tuotteen sinulle keskusvarastoltamme myymälään
          </strong>{' '}
          noudettavaksi, palauta tuote palautustarran avulla Postin kautta.
          Tunnistat keskusvarastolta toimitetun tuotteen siitä, että se on
          pakattu erilliseen pahvilaatikkoon tuotteen alkuperäispakkauksen
          lisäksi. Pahvilaatikossa on mukana myös palautustarra.
        </li>
        <li>
          <strong>
            Jos toimitimme tuotteen kotiin tai Postin noutopisteeseen
          </strong>
          , palauta tuote palautustarran avulla Postin kautta.
        </li>
      </ul>
      <p>
        Huom! Jos tilasit polkupyörän, niin katso palautusohjeet painamalla
        alempana olevaa painiketta. Tee palautus pyörän toimitustavan mukaisten
        ohjeiden mukaan.
      </p>
      <p>
        Jos muutat mielesi palautuksen rekisteröinnin jälkeen ja haluat pitää
        yhden tai useamman tuotteen, voit vain unohtaa palautuksen – erillistä
        yhteydenottoa asiakaspalveluun ei tarvita.
      </p>
    </>
  )
  const SokosReturnInfo = <p>{textProperties.returnRegisteredInfo}</p>

  return (
    <Col xs={12} className="py-3">
      <Row className="py-2">
        <Col xs={12}>
          <div className="d-flex align-items-center mb-2">
            <h2>{textProperties.returnRegisteredHeader}</h2>
          </div>
          {store === 'prisma' ? PrismaReturnInfo : SokosReturnInfo}
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={9}>
          <a
            href={textProperties.onlineStoreReturnPolicyUrl}
            className="btn btn-primary font-weight-bold pl-lg-5 pr-3 py-2 d-flex justify-content-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            {textProperties.returnInfoLink}
            <img src={linkIcon} alt="link-icon" className="px-2" />
          </a>
        </Col>
      </Row>
    </Col>
  )
}

export default SimplifiedReturnInfo
