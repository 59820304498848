import { CommonTextProperties } from '../models/text-properties-model'

const textProperties: CommonTextProperties = {
  onlineStoreItemReturn: 'Verkkokaupasta ostettujen tuotteiden palautus',
  orderNumber: 'Tilausnumero',
  startReturn: 'Aloita palauttaminen',
  needHelp: 'Tarvitsetko apua?',
  frequentlyAsked: 'Usein kysyttyä palautuksista',
  onlineStoreReturnPolicy: 'Verkkokaupan palautusehdot',
  reclamations: 'Reklamaatiot',
  customerServiceHelps: 'Asiakaspalvelumme auttaa',
  sokAddress: '© SOK | Fleminginkatu 34, PL1, 00088',
  businessId: 'S-ryhmä Y-tunnus 0116323-1',
  chooseItems: 'Valitse palautettavat tuotteet',
  returnLink: 'Takaisin',
  youAreReturning: 'Olet palauttamassa',
  accountNumber: 'Tilinumero, johon hyvitys maksetaan',
  giveIBAN: 'Anna tilinumero IBAN-muodossa',
  confirmReturn: 'Vahvista palautus',
  allProductsNotShipped:
    'Tilauksella on yksi tai useampi tuote, jota ei ole vielä toimitettu. Palautuksen käsittelyn sujuvoittamiseksi suosittelemme palauttamaan kaikki tuotteet kerralla.',
  reasonForReturn: 'Palautuksen ensisijainen syy',
  reasons: {
    PRODUCT_DOESNT_MATCH_DESCRIPTION: 'Tuote ei vastaa tuotekuvausta',
    PRODUCT_DOESNT_PLEASE: 'Tuote ei miellytä',
    PRODUCT_SIZE_TOO_LARGE: 'Tuote on liian suuri',
    PRODUCT_SIZE_TOO_SMALL: 'Tuote on liian pieni',
    PRODUCT_BAD_QUALITY: 'Tuote on laadultaan huono',
    PRODUCT_FAULTY: 'Tuote on viallinen',
    PRODUCT_WRONG_PRODUCT: 'Tuote on väärä',
    DELIVERY_TOO_SLOW: 'Toimitus kesti liian kauan',
    OTHER: 'Muu syy',
  },
  size: 'Koko',
  color: 'Väri',
  price: 'Hinta',
  loading: 'Ladataan...',
  pageNotAvailable: 'Valitettavasti tätä sivua ei ole olemassa',
  quantityToReturn: 'Palautettava määrä',
  waitingForShipping: 'Odottaa toimitusta',
  bankAccountNumberIsNotValid: 'Tilinumero ei ole oikeassa muodossa',
  oneProduct: ' tuotteen ',
  multipleProducts: ' tuotetta ',
  returnConfirmed: 'Palautus ilmoitettu',
  confirmationInfo:
    'Lähetimme vahvistuksen palautuksesta ja palautusohjeet sähköpostiisi.',
  howToReturn: 'Näin teet palautuksen:',
  packProductsHeader: '1. Pakkaa tuotteet ja liimaa osoitekortti pakettiin',
  packProductsText:
    'Pakkaa tuotteet alkuperäiseen tai vastaavaan pakkaukseen. Liitä toimituksen mukana tullut palautuskortti pakkauksen päälle niin, että vanhat osoitetiedot peittyvät. Jos palautuskortti on kadonnut, avaa postitusohje tästä.',
  bringPackageHeader: '2. Vie paketti palautuspisteeseen',
  bringPackageText:
    'Vie paketti mihin tahansa Postin toimipisteeseen tai samaan pakettiautomaattiin, josta noudit tilauksen. Jos hait tilauksen myymälästä, voit palauttaa sen samaan myymälään.',
  getRefundHeader: '3. Saat hyvityksen tilillesi',
  getRefundText:
    'Palautamme maksusi heti, kun olemme vastaanottaneet ja käsitelleet palautuksesi, yleensä 14 päivän sisällä. Saat sähköpostitse vahvistuksen, kun hyvitys on maksettu.',
  checkClosestPoints: 'Katso lähimmät palautuspisteet',
  returnDone: 'Palautus ilmoitettu',
  returnHandled: 'Palautus käsitelty',
  refundPaid: 'Hyvitys maksettu',
  returnForm: 'Palautuslomake',
  termsOfUse: 'Käyttöehdot',
  shippingAndDelivery: 'Tilaus- ja toimitusehdot',
  privacyNotice: 'Tietosuoja ja turvallisuus',
  additionalInfo: 'Lisätietoja:',
  productConfirm:
    'Olen tarkistanut, että tuotteella on palautusoikeus verkkokaupasta ja että se on käyttämätön ja myyntikuntoinen.',
  productConfirmNotification:
    'Tarkista, että olet vahvistanut kaikille palautettaville tuotteille, että niillä on palautusoikeus verkkokaupasta ja että ne ovat käyttämättömiä ja myyntikuntoisia.',
  returnInfoLink: 'Katso palautusohjeet',
  returnRegisteredHeader: 'Palautuksesi on rekisteröity',
  genericErrorMessage: 'Jotain meni pieleen, yritä uudelleen',
}

export default textProperties
