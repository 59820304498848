import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './common/redux/store'

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <React.StrictMode>
        <HelmetProvider>
          <Router>
            <App />
          </Router>
        </HelmetProvider>
      </React.StrictMode>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
