import { IDraftReturnStartItem } from '../models/om-models'

export default class CalculationFunctions {
  static calculateRetailPriceForQuantity(
    itemToCalculate: IDraftReturnStartItem,
    quantity: number,
  ): string {
    const itemPrice = parseFloat(itemToCalculate.unitPrice) * quantity
    const discounts = itemToCalculate.discounts.reduce(
      (accumulatedDiscount, discount) => {
        const lineDiscount = parseFloat(discount.amount)
        const fullLineQuantity = parseFloat(itemToCalculate.quantity)
        const discountForQuantity = (quantity / fullLineQuantity) * lineDiscount

        return discount.usage === 'Shipping Adjustment'
          ? accumulatedDiscount
          : accumulatedDiscount + discountForQuantity
      },
      0,
    )
    const discountedPrice = itemPrice + discounts

    return discountedPrice.toString()
  }
}
