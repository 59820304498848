import React, { CSSProperties, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import FormLabel from 'react-bootstrap/FormLabel'
import { SelectCallback } from 'react-bootstrap/esm/helpers'
import Row from 'react-bootstrap/Row'
import properties from '../helpers/properties'
import {
  IDraftReturnLine,
  IDraftReturnStartItem,
  ReturnReason,
} from '../models/om-models'
import '../styles/product.scss'
import Alert from './Alert'
import { useAppSelector } from '../redux/hooks'
import CalculationFunctions from '../helpers/calculationFunctions'

type ProductProps = {
  item: IDraftReturnStartItem
  isShipped: boolean
  setDraftReturnLinesHandler: (line: DraftReturnLine) => void
  deleteDraftReturnLinesHandler: (itemId: string) => void
}

export type DraftReturnLine = IDraftReturnLine & {
  reasonKey: ReturnReason | undefined
  confirmed: boolean
}

function Product(props: ProductProps): JSX.Element {
  const {
    item,
    isShipped,
    setDraftReturnLinesHandler,
    deleteDraftReturnLinesHandler,
  } = props

  const textProperties = useAppSelector((state) => state.textProperties.value)

  const [open, setOpen] = useState<boolean>(false)
  const [draftReturnLine, setDraftReturnLine] = useState<DraftReturnLine>({
    itemId: item.itemId,
    lineNo: item.orderLineNo,
    quantity: 1,
    returnReason: undefined,
    returnReasonDescription: undefined,
    reasonKey: undefined,
    confirmed: false,
  })

  const update = (returnLine: DraftReturnLine): void => {
    const line = {
      ...returnLine,
      quantity: returnLine.quantity === 0 ? 1 : returnLine.quantity,
    }
    setDraftReturnLinesHandler(line)
  }

  const remove = (itemId: string): void => {
    deleteDraftReturnLinesHandler(itemId)
  }

  const formattedPrice = (price: string): string => {
    let fixedPrice = parseFloat(price).toFixed(2)
    fixedPrice = fixedPrice.replace('.', ',')
    return fixedPrice
  }

  const imageUrl = (ean: string): string => {
    return properties.imageUrl + ean + properties.imageUrlParams
  }

  const onReasonSelect: SelectCallback = (eventKey: string | null): void => {
    if (eventKey) {
      const tmpReason = textProperties.reasons[
        eventKey as ReturnReason
      ] as ReturnReason
      if (tmpReason !== textProperties.reasons.OTHER) {
        const returnLine = {
          ...draftReturnLine,
          returnReason: tmpReason,
          reasonKey: eventKey as ReturnReason,
          returnReasonDescription: undefined,
        }
        setDraftReturnLine(returnLine)
        update(returnLine)
      } else {
        const returnLine = {
          ...draftReturnLine,
          returnReason: tmpReason,
          reasonKey: eventKey as ReturnReason,
        }
        setDraftReturnLine(returnLine)
        update(returnLine)
      }
    }
  }

  const onAdditionalInfoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const returnLine = {
      ...draftReturnLine,
      returnReasonDescription: e.target.value,
    }
    setDraftReturnLine(returnLine)
    update(returnLine)
  }

  const onConfirmChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const returnLine = {
      ...draftReturnLine,
      confirmed: e.target.checked,
    }
    setDraftReturnLine(returnLine)
    update(returnLine)
  }

  const onQuantitySelect: SelectCallback = (eventKey: string | null): void => {
    const returnLine: DraftReturnLine = {
      ...draftReturnLine,
      quantity: eventKey !== null ? JSON.parse(eventKey) : 0,
    }
    setDraftReturnLine(returnLine)
    update(returnLine)
  }

  const quantityGreater = (): boolean => {
    const numberQuantity: number = +item.pickedQuantity
    return numberQuantity > 1
  }

  const viewMore = (): void => {
    if (isShipped) {
      if (open) {
        remove(item.itemId)
      } else {
        update(draftReturnLine)
      }
      setOpen(!open)
    }
  }

  return (
    <div className="product p-2 pb-3 mb-3">
      <Container>
        <Row>
          <Button
            onClick={viewMore}
            className="w-100 d-flex product-button"
            aria-label="Select"
            style={{ cursor: isShipped ? 'pointer' : 'default' }}
          >
            <Col xs={11} className="text-left">
              <Row>
                <Col>
                  <div className="font-weight-bold mb-3">{item.itemName}</div>
                </Col>
              </Row>
              <Row>
                <Col xs={3} lg={2} className="pr-1 pr-lg-0">
                  <img src={imageUrl(item.ean)} alt={item.itemName} />
                </Col>
                <Col className="pl-2 pl-lg-0">
                  {item.size.length > 0 ? (
                    <Row>
                      <Col>
                        {textProperties.size}: {item.size}
                      </Col>
                    </Row>
                  ) : null}
                  {item.color.length > 0 ? (
                    <Row>
                      <Col>
                        {textProperties.color}: {item.color}
                      </Col>
                    </Row>
                  ) : null}
                  {item.linePrice.length > 0 ? (
                    <Row>
                      <Col>
                        {textProperties.price}:{' '}
                        {formattedPrice(
                          CalculationFunctions.calculateRetailPriceForQuantity(
                            item,
                            1,
                          ),
                        )}
                      </Col>
                    </Row>
                  ) : null}
                  {!isShipped ? (
                    <Row>
                      <Col xs={12} xl={8} className="mt-2">
                        <Alert
                          variant="info"
                          text={textProperties.waitingForShipping}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {isShipped ? (
              <Col xs={1} className="ml-2 ml-lg-4">
                <span
                  aria-label="Selected"
                  className={
                    open
                      ? 'checkbox checkbox-selected'
                      : 'checkbox checkbox-not-selected'
                  }
                />
              </Col>
            ) : null}
          </Button>
        </Row>
        <Row>
          <Col sm={10}>
            <Collapse in={open}>
              <div>
                {quantityGreater() ? (
                  <Dropdown className="dropdown-box mt-2 p-2">
                    <FormLabel>{textProperties.quantityToReturn}</FormLabel>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-quantity"
                    >
                      <div id="quantity-dropdown-selection">
                        {draftReturnLine.quantity}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {[...Array(parseFloat(item.pickedQuantity))].map(
                        (x, i: number) => (
                          <Dropdown.Item
                            key={`item_${i + 1}`}
                            eventKey={i + 1}
                            onSelect={onQuantitySelect}
                            active={draftReturnLine.quantity === i + 1}
                          >
                            {i + 1}
                          </Dropdown.Item>
                        ),
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                <Dropdown className="dropdown-box mt-2 p-2">
                  <FormLabel>{textProperties.reasonForReturn}</FormLabel>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-reason"
                  >
                    <div id="reason-dropdown-selection" className="text-left">
                      {draftReturnLine.returnReason}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {Object.keys(textProperties.reasons)
                      .map((areason) => areason as ReturnReason)
                      .map((areason) => (
                        <Dropdown.Item
                          key={areason}
                          eventKey={areason}
                          onSelect={onReasonSelect}
                          active={draftReturnLine.reasonKey === areason}
                        >
                          {textProperties.reasons[areason]}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="mt-2">
                  <div className="mt-4">{textProperties.additionalInfo}</div>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={onAdditionalInfoChange}
                    />
                  </Form.Group>
                </div>
                <div className="mt-2">
                  <Form.Group
                    controlId={`exampleForm.ControlCheck.${draftReturnLine.itemId}`}
                  >
                    <Form.Check
                      onChange={onConfirmChange}
                      label={textProperties.productConfirm}
                    />
                  </Form.Group>
                </div>
              </div>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

type ButtonProps = React.HTMLProps<HTMLButtonElement>

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (buttonProps, ref) => (
    <button
      className="w-100 d-block dropdown-box-button"
      type="button"
      ref={ref}
      onClick={(e): void => {
        e.preventDefault()
        if (buttonProps.onClick) buttonProps.onClick(e)
      }}
    >
      <Row>
        <Col className="d-flex justify-content-start">
          {buttonProps.children}
        </Col>
        <Col xs={1} className="d-flex justify-content-end pr-4">
          <div>
            <span className="arrow arrow-down" />
          </div>
        </Col>
      </Row>
    </button>
  ),
)

/* eslint-disable react/require-default-props */
type MenuProps = {
  children?: React.ReactNode
  style?: CSSProperties
  className?: string
}
/* eslint-enable react/require-default-props */

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<HTMLDivElement, MenuProps>(
  (menuProps, ref) => {
    return (
      <div ref={ref} style={menuProps.style} className={menuProps.className}>
        <ul className="list-unstyled">
          {React.Children.toArray(menuProps.children).map((child) => child)}
        </ul>
      </div>
    )
  },
)

export default Product
