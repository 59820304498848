import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useAppSelector } from '../redux/hooks'
import Alert from './Alert'

function ErrorInfo(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)
  return (
    <Col xs={12} sm={7} className="pb-3 errorinfo">
      <Row className="py-3">
        <Col xs={12} sm={9}>
          <Alert variant="info" text={textProperties.pageNotAvailable} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={7}>
          <Button
            className="mt-3 mt-sm-0 py-2 mb-5 w-100 font-weight-bold"
            href={textProperties.storeUrl}
          >
            {textProperties.toStore}
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

export default ErrorInfo
