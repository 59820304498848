import React from 'react'
import { useAppSelector } from '../redux/hooks'

function HelpBox(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)

  return (
    <div className="beige-box d-flex flex-column p-3">
      <h2>{textProperties.needHelp}</h2>
      <a
        className="App-link py-2"
        href={textProperties.frequentlyAskedUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textProperties.frequentlyAsked}
      </a>
      <a
        className="App-link py-2"
        href={textProperties.onlineStoreReturnPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textProperties.onlineStoreReturnPolicy}
      </a>
      {/* Commented out until reclamations page is implemented in store side <a
        className="App-link py-2"
        href={textProperties.reclamationsUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textProperties.reclamations}
      </a> */}
    </div>
  )
}

export default HelpBox
