import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TextProperties } from '../../models/text-properties-model'
import commonProperties from '../../helpers/commonTextProperties'
import textPropertiesSokos from '../../helpers/textProperties-sokos'
import textPropertiesPrisma from '../../helpers/textProperties-prisma'

export const fetchTextProperties = createAsyncThunk(
  'fetchTextProperties',
  async (storeToUse: string | undefined) => {
    return storeToUse === 'sokos'
      ? { ...commonProperties, ...textPropertiesSokos }
      : { ...commonProperties, ...textPropertiesPrisma }
  },
)

// Define a type for the slice state
interface TextPropertiesState {
  value: TextProperties
}

// Define the initial state using that type
const initialState: TextPropertiesState = {
  value: { ...commonProperties, ...textPropertiesSokos },
}

export const textPropertiesSlice = createSlice({
  name: 'textProperties',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setProperties: (state, action: PayloadAction<TextProperties>) => {
      state.value = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTextProperties.fulfilled, (state, action) => {
      state.value = action.payload
    })
  },
})

export const { setProperties } = textPropertiesSlice.actions

export default textPropertiesSlice.reducer
