import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useAppSelector } from '../redux/hooks'
import '../styles/tracking.scss'

function Tracking(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)
  return (
    <Col xs={12} className="pb-4">
      <Row className="d-flex flex-column">
        <Col xs={12} className="px-4">
          <div className="line d-flex justify-content-between pb-5">
            <div className="tracking-point active">
              <span className="checkbox-selected tracking-check" />
              <span className="marker-text mt-4 pt-3">
                {textProperties.returnDone}
              </span>
            </div>
            <div className="tracking-point">
              <span className="marker-text mt-4 pt-3">
                {textProperties.returnHandled}
              </span>
            </div>
            <div className="tracking-point">
              <span className="marker-text mt-4 pt-3">
                {textProperties.refundPaid}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default Tracking
