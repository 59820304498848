import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import phone from '../img/phone.svg'
import { useAppSelector } from '../redux/hooks'

function CustomerServiceInfo(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)
  return (
    <div className="my-4">
      <h2 className="mb-3">{textProperties.customerServiceHelps}</h2>
      <Row>
        <Col xs={1} sm={2}>
          <img src={phone} alt="phone" />
        </Col>
        <Col className="ml-4 ml-lg-2 ml-xl-0 px-sm-0">
          <a
            className="App-link py-2"
            href={textProperties.customerServiceUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {textProperties.customerServiceUrlLabel}
          </a>
          <div>{textProperties.customerServiceOpeningTimes}</div>
        </Col>
      </Row>
    </div>
  )
}

export default CustomerServiceInfo
