import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CustomerServiceInfo from '../components/CustomerServiceInfo'
import HelpBox from '../components/HelpBox'
import Tracking from '../components/Tracking'
import textProperties from '../helpers/commonTextProperties'
import SimplifiedReturnInfo from '../components/SimplifiedReturnInfo'

function ConfirmationView(): JSX.Element {
  const [, , removeCookie] = useCookies(['returnNumber'])

  useEffect(() => {
    removeCookie('returnNumber')
  }, [removeCookie])

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <h1>{textProperties.returnConfirmed}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} className="pb-3">
          <Tracking />
          {/* NOTE: ESCM-888 */}
          {/* <Alert variant="info" text={textProperties.confirmationInfo} /> */}
          <SimplifiedReturnInfo />
        </Col>
        <Col xs={12} sm={{ span: 5, offset: 1 }}>
          <HelpBox />
          <CustomerServiceInfo />
        </Col>
      </Row>
    </div>
  )
}

export default ConfirmationView
